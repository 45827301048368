import type { AlgoliaEventItem } from '~/composables/types/algolia'
import type { EventDescription } from '~/composables/types/event'

export const parseEventDescriptionToAlgoliaEventItem = (
  item: EventDescription
): Partial<AlgoliaEventItem> => {
  return {
    categories: eventGetters.getCategories(item),
    title: eventGetters.getTitle(item),
    default_duration: eventGetters.getEventMaximumDuration(item),
    default_price: eventGetters.getDefaultPriceObject(item),
    hashed_id: eventGetters.getId(item),
    images: eventGetters.getGallery(item),
    permalink: eventGetters.getPermalink(item),
    supplier: eventGetters.getSupplierName(item),
    next_event: eventGetters.getNextInstanceInTimestamp(item),
    rating: eventGetters.getReviewsRating(item),
  }
}

export const transformToAlgoliaEvent = (data: { data: EventDescription[] }) =>
  (data?.data || []).map(parseEventDescriptionToAlgoliaEventItem)
